import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import { Link } from "react-scroll";
import ImageCard from "components/ImageCard";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { header, subheader, imageFileName, urlFirst, urlFirstText, urlFirstType, urlSecond, urlSecondText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const [expanded, setExpanded] = React.useState(false);
  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  let extraInfoPart1;
  if (urlFirst && urlFirstText) {
    if (urlFirstType == 'internal') {
      extraInfoPart1 = (
        <Link
          className="nav-link cursor-pointer"
          activeClass="active"
          to={urlFirst}
          spy
          smooth="easeInOutQuart"
          onClick={closeMenu}
        >
          <Button size="xl" variant="primary" className="text-uppercase">
            {urlFirstText}
          </Button>
        </Link>
      );
    }
    else {
      extraInfoPart1 = (
        <a href={urlFirst}>
          <Button size="xl" variant="primary" className="text-uppercase">
            {urlFirstText}
          </Button>
        </a>
      );
    }
  }

  let extraInfoPart2;
  if (urlSecond && urlSecondText) {
    extraInfoPart2 = (
      <a href={urlSecond}>
        <Button size="xl" variant="secondary" className="text-uppercase">
          {urlSecondText}
        </Button>
      </a>
    );
  }

  return (
    <ImageCard
      imageFileName={imageFileName}
      header={header}
      subheader={subheader}
      extraInfo1={extraInfoPart1}
      extraInfo2={extraInfoPart2}
    />
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
