import React from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import Image from "components/Image";
import Icon from "components/Icon";

import "./PublicationsItem.scss";

const PublicationsItem = ({
    imageFileName,
    fileName,
    imageAlt,
    header,
    subheader,
    buttonText,
}) => {


    let imageItem;
    if (imageFileName) {
        imageItem = (<Image
            className="img-flutid"
            fileName={imageFileName}
            alt={imageAlt || header || subheader}
        />);
    }

    let button_text = (buttonText ? buttonText : 'Download');

    return (
        <>
            <Col md={4} sm={6} className="publications-item">

                {imageItem}
                <div className="publications-caption">
                    <h4>{header}</h4>
                    {subheader ? <p>{subheader}</p> : null}
                </div>

                <a
                    role="button"
                    tabIndex={-1}
                    className="publications-link"
                    data-toggle="modal"
                    href={fileName}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="publications-caption">
                        <Icon className="publications-icon" iconName="DownloadIcon" size="1x" />
                        <strong>{button_text}</strong>
                    </div>
                </a>

            </Col>
        </>
    );
};

PublicationsItem.propTypes = {
    imageFileName: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    buttonText: PropTypes.string,
};

PublicationsItem.defaultProps = {
    imageAlt: "",
    subheader: "",
};

export default PublicationsItem;
